.quill {
  font-family: var(--font-family);

  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  .ql-toolbar {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: var(--gray-300);
    border-color: var(--gray-300);
    &.ql-snow .ql-picker.ql-expanded .ql-picker-label {
      color: white;
      outline: none;
      border-color: white;
      font-family: 'Courier New', Courier, monospace;
    }
  }

  .ql-container {
    flex: 1 1 auto;

    font-size: 16px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-color: var(--gray-300) !important;
    overflow: hidden;
  }
  &.required {
    .ql-toolbar {
      border-color: var(--red-600);
      border-bottom-color: var(--gray-300);
    }
    .ql-container {
      border-color: var(--red-600) !important;
    }
  }
}
.ql-container {
  .ql-editor {
    min-height: 120px;
    max-height: 480px;
    overflow: auto;
  }
}
.resize-quill {
  .ql-editor {
    max-height: 100%;
    overflow-y: scroll;
    resize: vertical;
  }
}
.ql-editor blockquote {
  border-left: 4px solid #ccc;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 16px;
}
.ql-editor.ql-blank {
  &::before {
    opacity: 70%;
    font-style: normal;
  }
}
