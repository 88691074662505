.time-range-select {
  .time-range-input {
    padding: 4px 4px;
    &.time-range-input-disabled {
      background-color: #e4e7ec;
    }
  }
}

.date-time-select {
  padding: 0.75rem;
  cursor: pointer;
  position: relative;
  border: 1px solid var(--gray-300);
  border-radius: 6px;
  transition: all 300ms;

  &.p-invalid {
    border-color: var(--red-500);
  }
  &.date-time-select-placeholder {
    color: #4b5563;
    opacity: 0.7;
  }
  &.date-time-select-disabled {
    background-color: #e4e7ec;
  }
  &.date-time-select-focus {
    box-shadow: 0 0 0 0.2rem #fbcfe8;
    border-color: #d23c73;
  }
  .date-time-select-icon {
    position: absolute;
    right: 0;
    top: 0;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;

    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    background-color: var(--gray-300);
    height: 100%;
  }
}
