@use 'sass:math';

.p-tag {
  background: $badgeBg;
  color: $badgeTextColor;
  font-size: $badgeFontSize;
  font-weight: $badgeFontWeight;
  padding: $tagPadding;
  border-radius: $borderRadius;

  &.p-tag-success {
    background-color: $successTagBg;
    color: $successTagTextColor;
  }

  &.p-tag-info {
    background-color: $infoTagBg;
    color: $infoTagTextColor;
  }

  &.p-tag-warning {
    background-color: $warningTagBg;
    color: $warningTagTextColor;
  }

  &.p-tag-danger {
    background-color: $dangerTagBg;
    color: $dangerTagTextColor;
  }
  &.p-tag-inactive {
    background-color: #d0d5dd;
    color: #667085;
  }
  &.p-tag-purpil {
    background-color: #dab6fc;
    color: #5c2f88;
  }
  .p-tag-icon {
    margin-right: math.div($inlineSpacing, 2);
    font-size: $badgeFontSize;

    &.p-icon {
      width: $badgeFontSize;
      height: $badgeFontSize;
    }
  }
}
