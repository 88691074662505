.p-metergroup {
    .p-metergroup-meter-container {
        background: $progressBarBg;
        border-radius: $borderRadius;
    }

    .p-metergroup-meter {
        border: $progressBarBorder;
        background: $progressBarValueBg;
    }

    .p-metergroup-label-list {
        .p-metergroup-label-list-item {
            line-height: $progressBarHeight;
        }
        
        .p-metergroup-label-type {
            background: $progressBarValueBg;
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 100%;
            margin-right: $inlineSpacing;
        }
        
        .p-metergroup-label {
            margin-right: $inlineSpacing * 2;
        }
    
        .p-metergroup-label-icon {
            width: 1rem;
            height: 1rem;
            margin-right: $inlineSpacing;
        }
    }


    &.p-metergroup-horizontal {
        .p-metergroup-meter-container {
            height: 0.5rem;
        }

        .p-metergroup-meter:first-of-type {
            border-top-left-radius: $borderRadius;
            border-bottom-left-radius: $borderRadius;
        }

        .p-metergroup-meter:last-of-type {
            border-top-right-radius: $borderRadius;
            border-bottom-right-radius: $borderRadius;
        }

        .p-metergroup-label-list-start {
            margin-bottom: $inlineSpacing * 2;
        }
    
        .p-metergroup-label-list-end {
            margin-top: $inlineSpacing * 2;
        }
    }

    &.p-metergroup-vertical {
        .p-metergroup-meter-container {
            width: 0.5rem;
            height: 100%;
        }

        .p-metergroup-meter:first-of-type {
            border-top-left-radius: $borderRadius;
            border-top-right-radius: $borderRadius;
        }
    
        .p-metergroup-meter:last-of-type {
            border-bottom-left-radius: $borderRadius;
            border-bottom-right-radius: $borderRadius;
        }

        .p-metergroup-label-list:not(.p-metergroup-label-list-start) {
            margin-left: $inlineSpacing * 2;
        }
    }
}