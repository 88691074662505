.skeleton-container {
  width: 100%;
  padding: 10px;
  height: 100%;
  margin: auto;
  background-color: #f9fafb;
}

.skeleton-placeholder {
  position: relative;
  background: #ccc;
  border-radius: 3px;
  overflow: hidden;
}

.skeleton-placeholder:after {
  content: '';
  position: absolute;
  height: 100%;
  width: 100px;
  left: -100px;
  top: 0;
  background: linear-gradient(to right, transparent, #ffffff70, transparent);
  animation: reflect 800ms ease-out infinite;
}

.skeleton-title {
  width: 80%;
  min-height: 40px;
  margin-bottom: 16px;
}

.skeleton-content {
  width: 100%;
  min-height: 40px;
  margin-bottom: 16px;
}

@keyframes reflect {
  to {
    left: calc(100% + 100px);
  }
}

.loading {
  align-items: center;
  display: flex;
  justify-content: center;
}

.loading__dot {
  animation: dot ease-in-out 1s infinite;
  background-color: var(--primary-500);
  display: inline-block;
  height: 6px;
  margin: 4px;
  width: 6px;
  border-radius: 50%;
}
.loading-white .loading__dot {
  animation: dotWhite ease-in-out 1s infinite;
}
.loading__dot:nth-of-type(2) {
  animation-delay: 0.2s;
}

.loading__dot:nth-of-type(3) {
  animation-delay: 0.3s;
}

@keyframes dot {
  0% {
    @apply bg-primary-300;
    transform: scale(1);
  }
  50% {
    @apply bg-primary-400;

    transform: scale(1.3);
  }
  100% {
    @apply bg-primary-500;
    transform: scale(1);
  }
}

@keyframes dotWhite {
  0% {
    background-color: var(--primary-500);
    transform: scale(1);
  }
  50% {
    background-color: white;
    transform: scale(1.3);
  }
  100% {
    background-color: white;
    transform: scale(1);
  }
}
